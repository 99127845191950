<template>
    <div class="content">
        <div class="top">
            <div class="among">
                <div class="userInfo">
                    <span style="margin-right: 20px">你好,欢迎光临CNCX商城</span>
                    <div style="margin-right: 20px" @click="$message.warning('请先登录')">购物车</div>
                    <div style="margin-right: 20px" @click="$message.warning('请先登录')">我的订单</div>
                    <div style="margin-right: 20px" @click="$message.warning('请先登录')">收藏夹</div>
                    <div style="margin-right: 20px">帮助中心</div>
                </div>
            </div>
        </div>
        <div class="con">
            <img src="@/assets/loginBg.png" alt="">
            <div class="login">
                <div class="title">登录</div>
                <div class="inp">
                    <div class="input">
                        <i class="el-icon-s-custom"></i>
                        <input type="text" v-model="phone" placeholder="请输入手机号码">
                    </div>
                </div>
                <div class="inp" v-if="codeShow" style="height: 45px;">
                    <div class="input">
                        <i class="el-icon-s-comment"></i>
                        <input type="text" v-model="phoneCode" placeholder="请输入手机验证码">
                    </div>
                    <div class="yzm cur" @click="codeClick" :style="{ backgroundColor: remainingTime ? '#999' : '#E1241B' }">
                        {{ remainingTime ? `${remainingTime} S` : '获取验证码' }}
                    </div>
                </div>
                <div class="inp" v-else style="height: 45px;">
                    <div class="input">
                        <i class="el-icon-view"></i>
                        <input  type="password" v-model="password" placeholder="请输入密码">
                    </div>
                </div>
                <div class="inp" v-if="passwordNewShow" style="height: 45px;">
                    <div class="input">
                        <i class="el-icon-view"></i>
                        <input type="password" v-model="passwordNew" placeholder="请输入你的新密码">
                    </div>
                </div>
                <div v-if="passwordNewShow" style="font-size: 14px;color: #666;margin-left: 10px;">密码只能包括“数字、字母或_”，其他无效</div>
                <div class="logon cur" v-if="passwordNewShow" @click="changePasswordClick">立即修改</div>
                <div class="logon cur" v-else @click="loginClick">点击登录</div>
                <div class="miMa">
                    <div class="l cur" @click="codeShowClick"> {{ codeShow ? '密码登录' : '验证码登录' }}</div>
                    <div class="r cur" @click="passwordNewShowClick">忘记密码?</div>
                </div>
            </div>
        </div>
        <div class="bq">@2019 - 2023 cnc-x.cn 版权所有 沪ICP备2021037330号-1</div>
    </div>
</template>
  
<script>
import { getPasswordLogin, postPhoneCodeLogin, getPhoneCode, postChangePassword } from "@/utils/api/myApi/index"
export default {
    name: "login",
    data() {
        return {
            codeShow: false,
            passwordNewShow: false,
            // 验证码
            phoneCode: '',
            // 手机号
            phone: '',
            // 密码
            password: '',
            // 新密码
            passwordNew: '',
            // 倒计时
            remainingTime: 0,
            countdownInterval: null
        };
    },
    watch: {
        phone: function (newPhone) {
            if (newPhone && newPhone.length > 11) {
                this.phone = newPhone.slice(0, 11);
            }
        }
    },
    mounted() {
    },
    beforeDestroy() {
        clearInterval(this.countdownInterval);
    },
    methods: {
        codeShowClick() {
            this.codeShow = !this.codeShow
            this.passwordNewShow = false
        },
        passwordNewShowClick() {
            this.codeShow = true
            this.passwordNewShow = true
        },
        // 倒计时
        startCountdown() {
            this.remainingTime = 10
            this.countdownInterval = setInterval(() => {
                // 更新剩余时间
                this.remainingTime--;

                // 倒计时结束
                if (this.remainingTime <= 0) {
                    clearInterval(this.countdownInterval);
                    this.countdownInterval = null;
                }
            }, 1000);
        },
        // 登录
        async loginClick() {
            const regex = /^1[0-9]{10}$/;
            if (!regex.test(this.phone)) return this.$message.warning('手机号格式错误!');
            if (this.codeShow) {
                if (!this.phoneCode) return this.$message.warning('验证码不能为空!');
                // 验证码登录
                const res = await postPhoneCodeLogin({
                    phone: this.phone,
                    phoneCode: this.phoneCode
                })
                if (res.data) {
                    this.$message.success('登录成功');
                    this.$store.commit("getUserInfoData")
                    localStorage.setItem('token', res.data);
                    if (localStorage.getItem('historyRoute')) {
                        this.$router.push(localStorage.getItem('historyRoute'))
                        localStorage.removeItem('historyRoute');
                    } else {
                        this.$router.push("/home?actNum=0")
                    }
                } else {
                    this.$message.error(res.message);
                }
            } else {
                if (!this.password) return this.$message.warning('密码不能为空!');
                // 密码登录
                const res = await getPasswordLogin({
                    phone: this.phone,
                    password: this.password
                })
                if (res.data) {
                    this.$message.success('登录成功');
                    this.$store.commit("getUserInfoData")
                    localStorage.setItem('token', res.data);
                    if (localStorage.getItem('historyRoute')) {
                        this.$router.push(localStorage.getItem('historyRoute'))
                        localStorage.removeItem('historyRoute');
                    } else {
                        this.$router.push("/home?actNum=0")
                    }
                } else {
                    this.$message.error(res.message);
                }
            }
        },
        // 获取验证码
        async codeClick() {
            const regex = /^1[0-9]{10}$/;
            if (!regex.test(this.phone)) return this.$message.warning('手机号格式错误!');
            if (this.remainingTime > 0) return
            const res = await getPhoneCode(this.phone)
            if (res.statusCode == 8200) {
                this.$message.success(res.message);
                this.startCountdown()
            } else {
                this.$message.error(res.message);
            }
        },
        // 修改密码
        async changePasswordClick() {
            if(!this.phone) return this.$message.error("请填写手机号")
            const regex2 = /^1[0-9]{10}$/;
            console.log(this.phone)
            console.log(!regex2.test(this.phone))
            if (!regex2.test(this.phone)) return this.$message.error('手机号格式错误!');
            if(!this.phoneCode) return this.$message.error("验证码不能为空")
            if(!this.passwordNew) return this.$message.error("新密码不能为空")
            const regex = /^[a-zA-Z0-9_]+$/;
            if (!regex.test(this.passwordNew)) return this.$message.error("非法格式")
            if (this.passwordNew.length < 6) return this.$message.error("密码长度最少6位")
            const res = await postChangePassword({
                phoneCode: this.phoneCode,
                passwordNew: this.passwordNew,
                phone: this.phone
            })
            if(res.data){
                this.$message.success("修改成功，请使用新密码登录")
                this.codeShowClick()
                this.phoneCode = ''
                this.passwordNew = ''
            } else {
                this.$message.error("操作失败")
            }
        }
    }
};
</script>
  
<style lang="less" scoped>
.content {
    width: 100%;

    >.top {
        width: 100%;
        height: 30px;
        background-color: #f5f5f5;
        border: 1px solid #e0e0e0;
        box-sizing: border-box;

        >.among {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            display: block !important;

            >.userInfo {
                width: auto;
                float: right;
                line-height: 30px;
                font-size: 12px;
                color: #333333;
                display: flex;

                >div {
                    cursor: pointer;
                    transition: all 0.3s;
                }

                >div:hover {
                    color: #ff4242;
                    transition: all 0.3s;
                }
            }
        }
    }

    >.con {
        width: 100%;
        height: 750px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #EBF0F5;

        >img {
            height: 400px;
            object-fit: contain;
        }

        >.login {
            width: 482px;
            height: auto;
            background: #fff;
            border-radius: 16px 16px 16px 16px;
            padding: 20px 40px;
            box-sizing: border-box;
            margin-left: 60px;

            >.title {
                font-size: 24px;
            }


            >.inp {
                width: 100%;
                display: flex;
                margin-top: 40px;
                align-items: center;
                border-bottom: 1px solid #f0f0f0;
                padding: 0 10px 10px;
                box-sizing: border-box;
                align-items: center;
                justify-content: space-between;

                >.input {
                    width: 100%;
                    display: flex;

                    >i {
                        font-size: 22px;
                        color: #999;
                        margin-right: 8px;
                        margin-top: 4px;
                    }

                    >input {
                        border: 0;
                        width: calc(100% - 150px);
                        height: 30px;
                        font-size: 16px;
                        color: #333;

                    }

                    >input:focus {
                        border: 0;
                        outline: none;
                    }
                }

                >.yzm {
                    width: 130px;
                    height: 40px;
                    text-align: center;
                    line-height: 40px;
                    background-color: #E1241B;
                    color: #fff;
                    border-radius: 5px;
                    font-size: 14px;
                }


            }

            >.logon {
                width: 100%;
                height: 50px;
                background-color: #E1241B;
                text-align: center;
                line-height: 50px;
                color: #fff;
                border-radius: 5px;
                margin-top: 35px;
                margin-bottom: 20px;
                font-size: 18px;
            }

            >.miMa {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                >.l {
                    color: #666;
                }

                >.r {
                    color: #E1241B;
                }
            }
        }
    }
}

.bq {
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    width: 100%;
    text-align: center;
    margin-top: 70px;
}
</style>
  